<template>
  <TheLinkButton v-if="url" :to="url" target="_blank" appearance="filled" color="blue"
    >Zu PowerPool
  </TheLinkButton>
  <p v-else>{{ content.typeNotSupportedMsg }}</p>
</template>

<script setup lang="ts">
import { ContractType } from "~/types/shared/contract-type";
import TheLinkButton from "~/components/01_atoms/TheLinkButton.vue";

const { $config } = useNuxtApp();

const content = {
  typeNotSupportedMsg: "Bisher können wir hier nicht auf PowerPool verlinken",
};

const props = defineProps<{
  contractId: string;
  contractType: ContractType;
}>();

const url = ref("");

const isProd = $config.public.brainApiBaseUrl === "https://api.polarstern-energie.de";

const powerContracts: ContractType[] = [
  ContractType.Power,
  ContractType.TenantPower,
  ContractType.TenantPowerShadow,
];

const gasContracts: ContractType[] = [ContractType.Gas];

if (powerContracts.includes(props.contractType)) {
  if (!isProd) {
    url.value = `https://test-polarstern.envera.de/Steuerelemente/Handler/DirectAccess.ashx?mandant=Test-Polarstern-Strom&target=9&targetID=${props.contractId}`;
  } else {
    url.value = `https://polarstern-strom.envera.de/Steuerelemente/Handler/DirectAccess.ashx?mandant=Polarstern-Strom&target=9&targetID=${props.contractId}`;
  }
}

if (gasContracts.includes(props.contractType)) {
  if (!isProd) {
    url.value = `https://test-polarstern.envera.de/Steuerelemente/Handler/DirectAccess.ashx?mandant=Test-Polarstern-Gas&target=9&targetID=${props.contractId}`;
  } else {
    url.value = `https://polarstern-gas.envera.de/Steuerelemente/Handler/DirectAccess.ashx?mandant=Polarstern-Gas&target=9&targetID=${props.contractId}`;
  }
}
</script>
