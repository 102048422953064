<template>
  <header class="z-11">
    <MetaNav />
    <ImpersonificationBar />
    <MainNav
      v-if="!hideMainNav"
      :sections="navSections"
      :mobile-sections="mobileNavSections"
      @nav-action="navigationItemAction"
    />
  </header>
</template>

<script setup lang="ts">
import MainNav from "./header/MainNav.vue";
import MetaNav from "./header/MetaNav.vue";
import ImpersonificationBar from "~/components/02_molecules/admin-area/ImpersonificationBar.vue";
import type { NavigationConfig, UserNav } from "~/types/shared/navigation";

const props = defineProps<{
  /**
   * Set to true to show the customer area nav instead of the main area nav.
   */
  customerNav?: boolean;
  /**
   * Set to true to hide the main nav (second level nav) (e.g. on admin tool pages).
   */
  hideMainNav?: boolean;
  routePrefix?: string;
}>();

const navigationItemAction = (item: string) => {
  if (item === "openChat") openChat();
};

const navigation = useRuntimeConfig().public.navigation as NavigationConfig;
const data = navigation.header;

let userNav: UserNav[] = [
  {
    title: "Übersicht",
    url: { current: props.routePrefix + routeTo("customerArea", "") },
  },
  {
    title: "Account",
    url: { current: props.routePrefix + routeTo("customerArea", "account") },
  },
  {
    title: "Verträge",
    url: { current: props.routePrefix + routeTo("customerArea", "vertraege") },
  },
  {
    title: "Weiterempfehlen",
    url: { current: props.routePrefix + routeTo("customerArea", "weiterempfehlen") },
  },
  {
    title: "Impactrechner",
    url: { current: props.routePrefix + routeTo("customerArea", "impact") },
  },
  {
    title: "Zur Polarstern-Seite",
    url: { current: routeTo("page", "") },
  },
];

// duplicate of components/03_organisms/CustomerHeroQuickLinks.vue
let mobileSections: UserNav[] = [
  {
    title: "Übersicht",
    url: { current: props.routePrefix + routeTo("customerArea", "") },
  },
  {
    title: "Account",
    url: { current: props.routePrefix + routeTo("customerArea", "account") },
  },
  {
    title: "Verträge",
    url: { current: props.routePrefix + routeTo("customerArea", "vertraege") },
  },
  {
    title: "Weiterempfehlen",
    url: { current: props.routePrefix + routeTo("customerArea", "weiterempfehlen") },
  },
  {
    title: "Impactrechner",
    url: { current: props.routePrefix + routeTo("customerArea", "impact") },
  },
  {
    title: "Rechnungen ansehen",
    url: {
      current: props.routePrefix + routeTo("customerAreaContracts", "rechnungen"),
    },
  },
  {
    title: "Zählerstand ändern",
    url: {
      current: props.routePrefix + routeTo("customerAreaContracts", "zaehlerstand"),
    },
  },
  {
    title: "Abschlag ändern",
    url: { current: props.routePrefix + routeTo("customerAreaContracts", "abschlag") },
  },
  {
    title: "Bankverbindung ändern",
    url: {
      current: props.routePrefix + routeTo("customerAreaContracts", "bankverbindung"),
    },
  },
  {
    title: "Kontaktdaten ändern",
    url: { current: props.routePrefix + routeTo("customerArea", "account") },
  },
  {
    title: "Zur Polarstern-Seite",
    url: { current: routeTo("page", "") },
  },
];

if (useCustomerStore().isExclusivelyTenantPowerCustomer) {
  const hiddenNavigationForTenantPowerCustomers = ["Weiterempfehlen"];

  interface NavigationInterface {
    title: string;
    url: { current: string };
  }

  const filterOutHiddenNavigationItems = (item: NavigationInterface[]) => {
    return item.filter(
      (item) => !hiddenNavigationForTenantPowerCustomers.includes(item.title),
    );
  };

  userNav = filterOutHiddenNavigationItems(userNav);
  mobileSections = filterOutHiddenNavigationItems(mobileSections);
}

const navSections = props.customerNav ? userNav : data;
const mobileNavSections = props.customerNav ? mobileSections : data;
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: -50px;

  @include breakpoint-from(small) {
    top: 0;
  }
}
</style>
