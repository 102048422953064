<template>
  <a class="skip-to-content-link" href="#main">Zum Inhalt</a>
</template>

<style lang="scss">
.skip-to-content-link {
  color: var(--link-color);
  background: var(--background);
  left: -100%;
  top: 0;
  position: absolute;
  z-index: 12;
  transition: left var(--transition-quick);
  padding: var(--gap-xs);
  margin: var(--gap-xs);

  &:focus-visible {
    position: absolute;
    z-index: 12;
    left: 0;
  }
}
</style>
